import React, { useState, memo } from 'react';
import ReactMapGL from 'react-map-gl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { geolocated } from 'react-geolocated';
import Locations from './Locations';
import './App.css';
import * as parkData from './data/dogruns.json';

function App({ ...props }) {
  const [viewport, setViewport] = useState({
    zoom: 10,
    width: '120vw',
    height: '110vh',
  });

  return !props?.isGeolocationAvailable ? (
    <div className="message">
      <h2>Your browser does not support Geolocation.</h2>
    </div>
  ) : !props?.isGeolocationEnabled ? (
    <div className="message">
      <h2>Geolocation is not enabled yet.</h2>
    </div>
  ) : props.coords ? (
    <div className="App">
      <div className="App-header">Dog Runs USA</div>
      <ReactMapGL
        transitionDuration={1000}
        latitude={props.coords.latitude}
        longitude={props.coords.longitude}
        {...viewport}
        mapStyle="mapbox://styles/bubbasdad/cj8krq0rm57zw2sor4hhor04o"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={(viewport) => setViewport(viewport)}
      >
        <Locations parkData={parkData} />
      </ReactMapGL>
    </div>
  ) : (
    <div className="progress-message">
      <div className="loader">
        <CircularProgress />
      </div>
      <h2>Getting your location data now.</h2>
    </div>
  );
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
    isOptimisticGeolocationEnabled: true,
  },
  userDecisionTimeout: 5000,
})(memo(App));
