import React, { useState, useEffect, memo } from 'react';
import { Marker, Popup } from 'react-map-gl';
import './App.css';

function Locations({ parkData }) {
  const [selectedPark, setSelectedPark] = useState(null);
  useEffect(() => {
    const keyboardListener = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keydown', keyboardListener);
    return () => {
      window.removeEventListener('keydown', keyboardListener);
    };
  }, []);

  const handleClose = () => {
    setSelectedPark(null);
  };

  return (
    <div>
      {parkData?.default?.features?.map((park) => (
        <Marker
          key={park.properties.place_id + park.properties.title}
          latitude={park.geometry.coordinates[1]}
          longitude={park.geometry.coordinates[0]}
          className="marker"
        >
          <div
            onClick={(event) => {
              event.preventDefault();
              setSelectedPark(park);
            }}
          >
            <img
              className="pup-button"
              alt="purple pup"
              src="/icon48.svg"
            ></img>
          </div>
        </Marker>
      ))}
      {selectedPark ? (
        <Popup
          latitude={selectedPark.geometry.coordinates[1]}
          longitude={selectedPark.geometry.coordinates[0]}
          onClose={handleClose}
          className="popup"
        >
          <div>
            <h3>{selectedPark.properties.title}</h3>
            <p>{selectedPark.properties.address}</p>
          </div>
        </Popup>
      ) : null}
    </div>
  );
}

export default memo(Locations);
